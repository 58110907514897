import i18n, { use, init } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enUS, fr, de } from 'date-fns/locale';

import translationEN from '../i18n/locales/en/translation.json';
import translationFR from '../i18n/locales/fr/translation.json';
import translationDE from '../i18n/locales/de/translation.json';

const locales = {
    en: enUS,
    fr,
    de,
};

const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    de: {
        translation: translationDE,
    },
};

use(initReactI18next);
use(LanguageDetector);
init({
    detection: {
        order: ['querystring', 'localStorage', 'navigator'],
        lookupLocalStorage: 'spacefillLocale',
        lookupQuerystring: 'lng', // use ?lng=fr in url to switch language
    },
    resources,
    whitelist: ['fr', 'en', 'de'],
    cleanCode: true,
    nonExplicitWhitelist: true,
    fallbackLng: 'en',
    keySeparator: '|',
    nsSeparator: false,
    interpolation: {
        escapeValue: false,
    },
    useSuspense: false,
    compatibilityJSON: 'v3',
});

const getDateFnsCurrentLocale = (locale) => locales[locale?.toLowerCase() ?? i18n.language.toLowerCase()];

export { i18n, getDateFnsCurrentLocale };
