import React from 'react';
import { NavigateWithParams } from '@spacefill/shared/src/utils/RouterUtils';

const LogisticManagementMasterItemsNewCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/New').then((module) => ({
        Component: module.LogisticManagementMasterItemsNew,
    }));
const LogisticManagementMasterItemsEditCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/Edit').then((module) => ({
        Component: module.LogisticManagementMasterItemsEdit,
    }));
const LogisticManagementMasterItemsListCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/List').then((module) => ({
        Component: module.LogisticManagementMasterItemsList,
    }));
const LogisticManagementMasterItemsViewCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/View').then((module) => ({
        Component: module.LogisticManagementMasterItemsView,
    }));

const LogisticManagementOrdersList = async () =>
    import('../../pages/logistic-management/warehouses/orders/List').then((module) => ({
        Component: module.LogisticManagementWarehousesOrdersList,
    }));
const LogisticManagementStockSituation = async () =>
    import('../../pages/logistic-management/inventory/stock-situation').then((module) => ({
        Component: module.LogisticManagementStockSituation,
    }));
const LogisticManagementStockSituationByWarehouse = async () =>
    import('../../pages/logistic-management/warehouses/inventory/stock-situation').then((module) => ({
        Component: module.LogisticManagementStockSituationByWarehouse,
    }));
const LogisticManagementWarehouseConfigurationsView = async () =>
    import('../../pages/logistic-management/warehouses/customer-warehouse-configurations/ViewAsShipperUser').then(
        (module) => ({ Component: module.WarehouseConfigurationsView })
    );
const LogisticManagementAccurateStockSituation = async () =>
    import('../../pages/logistic-management/inventory/accurate-stock-situation').then((module) => ({
        Component: module.LogisticManagementAccurateStockSituation,
    }));

const LogisticManagementOrdersImport = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/ImportOrders').then((module) => ({
        Component: module.LogisticManagementOrdersImport,
    }));

const LogisticManagementOrdersRedirectView = async () =>
    import('../../pages/logistic-management/orders/RedirectView').then((module) => ({
        Component: module.LogisticManagementOrdersRedirectView,
    }));
const LogisticManagementOrdersRedirectEdit = async () =>
    import('../../pages/logistic-management/orders/RedirectEdit').then((module) => ({
        Component: module.LogisticManagementOrdersRedirectEdit,
    }));

const LogisticManagementWarehousesOrdersView = async () =>
    import('../../pages/logistic-management/warehouses/orders/view/View').then((module) => ({
        Component: module.LogisticManagementWarehousesOrdersView,
    }));
const LogisticManagementWarehousesNewOrdersEntry = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/Entry').then((module) => ({
        Component: module.LogisticManagementWarehousesNewOrdersEntry,
    }));
const LogisticManagementWarehousesNewOrdersExit = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/Exit').then((module) => ({
        Component: module.LogisticManagementWarehousesNewOrdersExit,
    }));
const LogisticManagementWarehousesEditOrders = async () =>
    import('../../pages/logistic-management/warehouses/orders/edit/Edit').then((module) => ({
        Component: module.LogisticManagementWarehousesEditOrders,
    }));
const LogisticManagementWarehousesEditOrdersRedirectEntryOrExit = async () =>
    import('../../pages/logistic-management/warehouses/orders/edit/index').then((module) => ({
        Component: module.LogisticManagementWarehousesEditOrdersRedirectEntryOrExit,
    }));

const CustomerKeyPerformanceIndicationView = async () =>
    import('../../pages/logistic-management/analytics/View/View').then((module) => ({ Component: module.DataView }));

const DocumentsPage = async () =>
    import('../../pages/DocumentsPage').then((module) => ({ Component: module.DocumentsPage }));

const IncidentsView = async () =>
    import('../../pages/logistic-management/incidents/list/List').then((module) => ({
        Component: module.IncidentsView,
    }));

const getCustomerLogisticManagementRoutes = (user) => {
    if (user.role !== 'SHIPPER' || (user?.customerWarehouseConfigurationsWithLogisticManagement ?? []).length === 0) {
        return [];
    }

    return [
        // Global routes

        // Data View
        {
            path: '/logistic-management/analytics/',
            lazy: CustomerKeyPerformanceIndicationView,
            condition: user?.features?.hasAnalyticsEnabled,
        },

        // Incidents View
        {
            path: '/logistic-management/incidents/',
            lazy: IncidentsView,
        },

        //  Documents
        {
            path: '/documents/',
            lazy: DocumentsPage,
        },

        // Orders
        {
            path: '/logistic-management/orders/',
            lazy: LogisticManagementOrdersList,
        },
        {
            path: '/logistic-management/orders/:orderId/',
            lazy: LogisticManagementOrdersRedirectView,
        },
        {
            path: '/logistic-management/orders/import/',
            lazy: LogisticManagementOrdersImport,
        },
        {
            path: '/logistic-management/orders/:orderId/edit',
            lazy: LogisticManagementOrdersRedirectEdit,
        },

        // Master items
        {
            path: '/logistic-management/inventory/items/new',
            lazy: LogisticManagementMasterItemsNewCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/:masterItemId/edit',
            lazy: LogisticManagementMasterItemsEditCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/:masterItemId/',
            lazy: LogisticManagementMasterItemsViewCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/',
            lazy: LogisticManagementMasterItemsListCustomUnit,
        },

        // Stock situation
        {
            path: '/logistic-management/inventory/stock-situation/',
            lazy: LogisticManagementStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/',
            lazy: user.toggleFeatures.includes('accurateStock')
                ? LogisticManagementAccurateStockSituation
                : LogisticManagementStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/forecasted/',
            lazy: user.toggleFeatures.includes('accurateStock')
                ? LogisticManagementAccurateStockSituation
                : LogisticManagementStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/accurate/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/unique-identifier/',
            lazy: LogisticManagementStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/accurate/forecasted',
            lazy: LogisticManagementAccurateStockSituation,
        },

        // By warehouse

        // Orders
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/',
            lazy: LogisticManagementOrdersList,
        },

        // New entry
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/new-entry',
            lazy: LogisticManagementWarehousesNewOrdersEntry,
            conditionFailedRedirect: '/',
        },

        // New exit
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/new-exit',
            lazy: LogisticManagementWarehousesNewOrdersExit,
            conditionFailedRedirect: '/',
        },

        // View order
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/',
            lazy: LogisticManagementWarehousesOrdersView,
        },

        // Edit order
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/edit',
            lazy: LogisticManagementWarehousesEditOrdersRedirectEntryOrExit,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/edit-entry',
            lazy: LogisticManagementWarehousesEditOrders,
            condition: user?.customer?.orderManagementEnabled,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/edit-exit',
            lazy: LogisticManagementWarehousesEditOrders,
            condition: user?.customer?.orderManagementEnabled,
        },

        // Warehouses : redirect to stock situation
        {
            path: '/logistic-management/warehouses/:warehouseId',
            element: (
                <NavigateWithParams
                    to={(params) => `/logistic-management/warehouses/${params.warehouseId}/inventory/stock-situation/`}
                />
            ),
        },

        // Stock situation
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/',
            lazy: LogisticManagementStockSituationByWarehouse,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/',
            lazy: user.toggleFeatures.includes('accurateStock')
                ? LogisticManagementAccurateStockSituation
                : LogisticManagementStockSituationByWarehouse,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/forecasted',
            lazy: user.toggleFeatures.includes('accurateStock')
                ? LogisticManagementAccurateStockSituation
                : LogisticManagementStockSituationByWarehouse,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/accurate/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/accurate/forecasted',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/unique-identifier/',
            lazy: LogisticManagementStockSituationByWarehouse,
        },

        // Warehouse configuration
        {
            path: '/logistic-management/warehouses/:warehouseId/warehouse-configurations/:configurationId/',
            lazy: LogisticManagementWarehouseConfigurationsView,
        },
    ];
};

export { getCustomerLogisticManagementRoutes };
