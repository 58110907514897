import React from 'react';
import { NotFound } from '@spacefill/uikit/src/components/NotFound';
import { Button } from '@spacefill/uikit/src/components/Button';
import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getEnvironmentVariable } from '@spacefill/shared/src/utils/GetEnvVar';
export const ErrorComponent = () => {
    const error = useRouteError();
    const { t } = useTranslation();
    const externalConsoleServiceUrl = getEnvironmentVariable('externalConsoleServiceUrl');
    return (
        <NotFound
            errorCode=''
            errorMessage={t('An error occurred')}
            details={
                <pre className='p-4 my-2 font-["Courier"] leading-5 bg-violet-100 rounded-md'>
                    {error.message + '\n' + error.stack}
                </pre>
            }
            cta={
                <Button onClick={() => (window.location.href = externalConsoleServiceUrl)}>
                    {t('Return to the home page')}
                </Button>
            }
        />
    );
};
