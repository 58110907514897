import { HTMLAttributes, ReactElement, SVGProps } from 'react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import { type ButtonProps } from '../Button';
import { Accordion } from '../Accordion';

import SvgWarehouse from './icons/warehouse';
export interface NotFoundProps {
    variant?: 'default' | 'dark';
    errorCode?: number | null;
    errorMessage?: string;
    details?: string;
    cta?: ReactElement<ButtonProps>;
    IconComponent?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
}

export const NotFound = ({
    variant = 'default',
    errorCode = 404,
    errorMessage,
    IconComponent = SvgWarehouse,
    cta,
    dataTestid,
    details,
    htmlAttributes,
}: NotFoundProps) => {
    const { t } = useTranslation();
    return (
        <div
            className={clsx('relative h-full p-6', {
                'bg-gray-100 text-black': variant === 'default',
                'bg-zinc-900 text-white': variant === 'dark',
            })}
            data-testid={dataTestid}
            {...htmlAttributes}
        >
            <div className='absolute left-[5%] top-[15%] z-10'>
                {errorCode && <h1 className='my-4 font-campton text-8xl font-bold'>{errorCode} !</h1>}
                <p className='mb-8 font-campton text-2xl font-semibold'>
                    {errorMessage ?? `${t("For some reason, we couldn't load")} 😓`}
                </p>
                {details ? (
                    <Accordion
                        title={t('See error')}
                        content={details}
                    />
                ) : null}
                {cta && <div className='my-4'>{cta}</div>}
            </div>
            <div>
                <IconComponent
                    className={clsx('absolute bottom-0 right-0 m-2 mr-16 w-1/2', {
                        'text-white': variant === 'default',
                        'text-black': variant === 'dark',
                    })}
                />
            </div>
        </div>
    );
};
